<template>
<div>
    <section class="slice pt-9 pb-7 bg-light">
        <img src="/img/benefits.jpg" alt="Image" class="img-as-bg blend-mode--multiply">
        <span class="mask bg-gradient-dark opacity-2"></span>
        <div data-offset-top="#navbar-main">
            <div class="container d-flex align-items-center text-center text-lg-left">
                <div class="col px-0">
                    <div class="row justify-content-center">
                        <div class="col-lg-8 text-center">
                            <h2 class="h1 text-white mt-6 mb-2">Benefits</h2>
                            <h3 class="font-weight-light text-white opacity-8">Guaranteed Whole Life Insurance</h3>
                            <h3 class="font-weight-light text-white opacity-8">Individual Policy</h3>
                            <div class="my-3">
                                
                            </div>
                            <div class="row mt-5">
                                
                                
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="slice slice-lg">
        <div class="container">
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Acceptance is Guaranteed</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        You cannot be turned down for coverage because of your health or medical history. There are NO Health Questions. Available to US Citizens between the ages of 18 to 70 and at the time of application are working a minimum of 20+ hours per week and able to perform the duties of their Occupation.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Accidental Death Benefit</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        Included in the cost is an Accidental Death Benefit.  
                        Accidental Death Benefit not included in Issue Ages 56+.The Benefit does drop off at insured’s age 71 and the premium for the client drops down appropriately as well.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">No Medical Exams</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        There are no medical exams or lengthy health questionnaires required to be accepted for coverage. The state of your health cannot influence your eligibility for this policy. 
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Coverage for Life</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        Guaranteed lifelong coverage with premiums payable until age 95. At the minimum guarantee of 3%; at age 95 the insured's CV equals the Face Amount.   Current interest rate is 4.25%
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Guaranteed Rates</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                       Premiums are fixed for the life of the policy. Your current health and any future health changes will not affect your rates.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Cash Value</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                       Guaranteed Whole Life Insurance builds a cash value over time which you can borrow against and use as you need it.
                    </p>
                    
                </div>
            </div>
           
       
            <div class="row justify-content-between">
            <div class="col-lg-3 text-lg-right">
                <h4 class="mb-4">Apply</h4>
            </div>
            <div class="col-lg-8">
                <div class="card rounded-bottom-right">
                    <div class="card-body">
                        <div class="d-flex align-items-center mb-4">
                            <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Life Quote</h3>
                        </div>
                        <div class="row justify-content-center list-group-flush mb-4">
                            <div class="col-12">
                                <div id="form-contact">
                                    
                                    <div class="form-group">
                                        
                                        <select @change="amountChange" class="form-control custom-select-lg">
                                            <option selected>Amount</option>
                                            <option value="100">$100,000</option>
                                            <option value="95">$95,000</option>
                                            <option value="90">$90,000</option>
                                            <option value="85">$85,000</option>
                                            <option value="80">$80,000</option>
                                            <option value="75">$75,000</option>
                                            <option value="70">$70,000</option>
                                            <option value="65">$65,000</option>
                                            <option value="60">$60,000</option>
                                            <option value="55">$55,000</option>
                                            <option value="50">$50,000</option>
                                            <option value="45">$45,000</option>
                                            <option value="40">$40,000</option>
                                            <option value="35">$35,000</option>
                                            <option value="30">$30,000</option>
                                            <option value="25">$25,000</option>
                                            <option value="20">$20,000</option>
                                            <option value="15">$15,000</option>
                                            <option value="10">$10,000</option>
                                            <option value="5">$5,000</option>
                                          </select>
                                    </div>
                                    <div class="form-group">
                                        
                                        <select @change="stateChange" class="form-control custom-select-lg">
                                            <option selected>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>     
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NM">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>

                                        </select>
                                    </div>
                                    <div class="form-group">
                                   
                                        <select @change="ssChange" class="form-control custom-select-lg">
                                            <option selected>Smoking Status</option>
                                            <option value="1">No</option>
                                            <option value="2">Yes</option>                                          
                                          </select>
                                    </div>
                                    <div class="form-group">
                                        <select @change="genderChange" class="form-control custom-select-lg">
                                            <option selected>Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                          </select>
                                    </div>
                                    
                                    <div class="form-group">
                                        
                                        <div class="row">
                                            <div class="col-4" style="padding-right:2px;">
                                                <select @change="changeMonth" class="form-control custom-select-lg">
                                                    <option selected>Month</option>
                                                    <option value="01">01 - January</option>
                                                    <option value="02">02 - February</option>
                                                    <option value="03">03 - March</option>
                                                    <option value="04">04 - April</option>
                                                    <option value="05">05 - May</option>
                                                    <option value="06">06 - June</option>
                                                    <option value="07">07 - July</option>
                                                    <option value="08">08 - August</option>
                                                    <option value="09">09 - September</option>
                                                    <option value="10">10 - October</option>
                                                    <option value="11">11 - November</option>
                                                    <option value="12">12 - December</option>
                                                </select> 
                                            </div>
                                            <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                               
                                                <select @change="changeDay" class="form-control custom-select-lg">
                                                    <option selected>Day</option>
                                                    <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                   
                                                </select> 
                                            </div>
                                            <div class="col-4" style="padding-left:2px;">
                                               <select @change="changeYear" class="form-control custom-select-lg" >
                                                    <option selected>Year</option>
                                                    <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                                </select> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="reset" class="btn-reset d-none">

                                        </button> 
                                        <button v-if="$store.getters.loggedIn" type="submit" @click="submitQuote" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                            <span v-if="loading == false">Get your Estimate</span>
                                            <span v-else>
                                                <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </button>
                                        <button v-else type="submit" @click="$router.push({name: 'Login'})" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                            <span v-if="loading == false">Login</span>
                                            
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            </div>
    </div>
</section>
</div>
</template>
<script>
import flatpickr from "flatpickr";
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    flatpickr,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>