<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Ticket Scheduling</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-10" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        <div class="col-md-12 mb-1" style="display: flex; justify-content: center;">
                            <avatar :username="$store.getters.returnUserName" :size="90" class="rounded-circle"> </avatar>
                        </div>
                        <div class="col-md-12 text-center">
                            <span class="d-block h6 mt-4 mb-0">{{$store.getters.returnUserName}}</span>
                        </div>
                        <div class="col-md-12">
                            <div class="card mt-5">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="">
                                            <div class="list-group list-group-flush">
                                                <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                                    <div class="row w-100 ml-5">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">First name</label> 
                                                                    <p class="h6">{{$parent.ticket.first_name}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Last name</label> 
                                                                    <p class="h6">{{$parent.ticket.last_name}}</p>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                                    <div class="row w-100 ml-5" style="margin: 0 auto;">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Email</label> 
                                                                    <p class="h6">{{$parent.ticket.email}}</p> 
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Phone</label> 
                                                                    <p class="h6">{{$parent.ticket.phone}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="list-group-item d-flex w-100 justify-content-between pb-5" style="border: 0px;">
                                                    <div class="row w-100" style="margin: 0 auto;">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label class="form-control-label">Select Timezone </label> 
                                                                <select @change="selectTimezone" class="form-control custom-select-lg">
                                                                    <option selected>Select from menu</option>
                                                                    <option value="America/Los_Angeles">Pacific Time - {{$moment(new Date()).tz('America/Los_Angeles').format('h:mm a')}}</option>
                                                                    <option value="America/Phoenix">Arizona Time - {{$moment(new Date()).tz('America/Phoenix').format('h:mm a')}}</option>
                                                                    <option value="America/Denver">Mountain Time - {{$moment(new Date()).tz('America/Denver').format('h:mm a')}}</option>
                                                                    <option value="America/Chicago">Central Time - {{$moment(new Date()).tz('America/Chicago').format('h:mm a')}}</option>
                                                                    <option value="America/New_York">Eastern Time - {{$moment(new Date()).tz('America/New_York').format('h:mm a')}}</option>
                                                                                                        
                                                                </select>
                                                            </div>
                                                        </div>
                                                        
                                                            <div v-if="timeZone" class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label text-center">Schedule Day </label> 
                                                                    <select @change="selectDateChange" class="form-control custom-select-lg">
                                                                        <option selected>Select Date</option>
                                                                        <option v-for="(i, index) in sSlots" :key="i.id" :value="index">{{i.day}}</option>                                          
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div v-if="timeZone" class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Schedule Time </label> 
                                                                    <select @change="selectedHour"  class="form-control custom-select-lg">
                                                                        <option selected>Select time</option>
                                                                        <option v-for="i in sHours" :key="i.id" :disabled="shouldDisableSlot(i)" :value="i.id">{{$moment(i.hour + 'z').tz(timeZone).format('h:mm a')}}</option>
                                                                                                            
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div v-if="timeZone" class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Enroller Options</label>
                                                                    <select @change="enrollerOption" class="form-control custom-select-lg">
                                                                        <option selected>Select Option</option>
                                                                        <option value="i">I will be accepting this time slot</option>
                                                                        <option v-if="$store.getters.isAdmin" value="other">Assign time slot to an enroller</option>
                                                                        <option value="later">Unassigned</option>
                                                                                                            
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div v-if="selectEnroller" class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Assign Enroller</label>
                                                                    <div v-if="!loadingES" class="form-group mt-2">
                                                                            <select @change="selectEnrollerChoose" class="custom-select custom-select-lg mb-3">
                                                                                <option selected>Open this select menu</option>
                                                                                <option v-for="i in enrollersList" :key="i.id" :value="i.id">{{i.user.name}}</option>
                                                                            
                                                                            </select>
                                                                    </div>
                                                                    <div v-else style="width:100%;height:60px;display:flex;align-items:center;justify-content:center;">
                                                                        <div class="spinner-border" role="status">
                                                                        <span class="sr-only">Loading...</span>
                                                                    </div>
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Notes </label>
                                                                    <textarea v-model="form.note" class="form-control" placeholder="Complete Event Notes" rows="4"></textarea>
                                                                </div>
                                                            </div>
                                                            
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6"></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                      
                        
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="completeTicket()"  type="submit" class="btn btn-success btn-loading">
                                
                                <span v-if="!loading">Confirm</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "scheduler",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            sSlots: '',
            sHours: '',
            loading: false,
            timeZone: '',
            form: {
                name: '',
                note: '',
                enroller_id: '',
            },
            selectEnroller: '',
            enrollersList: [],
            loadingES: false,
        }
    },
   created() {
       this.fetchScheduler();
   },
    methods: {
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.form.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.form.schedule_hourid = event.target.value;
            }
            
        },
        enrollerOption(event) {
            if(event.target.value == 'other') {
                this.selectEnroller = true;
                this.fetchEnrollers();
            } else if(event.target.value == 'i') {
                this.selectEnroller = false;
                this.form.enroller_id = 'me';
            } else {
                this.selectEnroller = false;
                this.form.enroller_id = null;
            }
        },
        fetchEnrollers() {
            this.loadingES = true;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchenrollers2').then(resp => {
                console.log(resp);
                this.enrollersList = resp.data.t;
                this.loadingES = false;
            });
        },
        selectEnrollerChoose(event) {
            if(event.target.value) {
                console.log(event.target.value);
                this.form.enroller_id = event.target.value;
            }
        },
        completeTicket() {
            if(this.form.schedule_hourid !== null && this.form.note !== '') {
                this.loading = true;
                if(this.selectEnroller == true && this.form.enroller_id == '') { 
                    this.$toasted.error('choose an enroller'); 
                    this.loading = false; 
                    return; 
                }
            
                this.form.hour_id = this.$parent.ticket.hour.id;
                this.form.user_id = JSON.parse(localStorage.getItem('access_token2')).id;
                this.form.ticket_id = this.$parent.ticket.id;
                /* this.form.note = this.$parent.ticket.note; */
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/rescheduleclient', this.form).then(resp => {
                    console.log(resp.data);
                    
                }).then(resp2 => {
                    this.loading = false;
                    
                    $(this.$parent.$refs.schedulerOpen.$el).modal('hide');
                    this.$router.go(-1);
                    this.$parent.$toasted.success("Completed");
                    
                }).catch(err => {
                    this.loading = false;
                    $(this.$parent.$refs.schedulerOpen.$el).modal('hide');
                });
            } else if(this.form.schedule_hourid == null) {
                this.$toasted.error('Select Hour');
            } else if(this.form.note == '') {
                this.$toasted.error('Event Notes');
            }
            
           

        }
    }
    
}
</script>
<style scoped>
.form-control option:disabled {
    display: none;
}
</style>