<template>
<div>
    
    <section class="pt-10 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0">Ticket Creation</h1>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12 mb-4">
                            <div v-for="i in $store.state.shelf" :key="i.id" class="card" style="margin-bottom:4px;">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h5 class="mb-0">Guaranteed Issue Whole Life {{i.name}}</h5>
                                                    <p class="text-muted lh-150 text-sm mb-0">Age {{i.age}} | Date of policy start: {{i.startdate}} </p>
                                                    <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2(i.amount * 1000)}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6">{{formatCurrency(i.quote)}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="$store.state.rider1" class="card" style="padding-top:0px;padding-bottom:0px;margin-bottom:4px">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-8">
                                            <div class="media align-items-center">
                                                <span class="avatar bg-success text-white rounded-circle mr-3">
                                                    <i class="uil uil-check" style="font-size: 1.75rem;"></i>
                                                </span>
                                                <div class="media-body">
                                                    <h6 class="mb-0">Guaranteed Issue Whole Life Child Rider</h6>
                                                    <p class="text-muted lh-150 text-sm mb-0">Coverage Amount: {{formatCurrency2($store.state.rider1.amount * 1000)}} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6">{{formatCurrency(this.$store.state.rider1.cost)}}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                
                                <h5 class="mb-3">Contact information</h5>
                               
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <input v-model="form.first_name" class="form-control" type="text" placeholder="Enter your first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <input v-model="form.last_name" class="form-control" type="text" placeholder="Also your last name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Date of Birth</label> 
                                                <input v-model="form.dob" type="text" class="form-control flatpickr-input" data-toggle="date" placeholder="Select your birth date">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Gender</label> 
                                                <select v-model="form.gender" class="custom-select">
                                                    <option disabled="disabled" selected="selected">Select option</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Rather not say">Rather not say</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="form.email" @blur="validateEmail" class="form-control" :class="{'outlineTicketSuccess' : emailc == 1, 'outlineTicketDanger' : emailc == 2}" type="email" placeholder="name@exmaple.com"> <small class="form-text text-muted mt-2">This is the main email address that we'll send notifications.</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email Confirmation</label> 
                                                <input @keyup="emailConfirm" class="form-control" :class="{'outlineTicketSuccess' : emailc == 1, 'outlineTicketDanger' : emailc == 2}" type="email" placeholder="name@exmaple.com"> 
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="form.phone" @input="acceptNumber" class="form-control" :class="{'outlineTicketSuccess' : phonec == 1, 'outlineTicketDanger' : phonec == 2}"  type="text" placeholder="(777) 245-549"><small class="form-text text-muted mt-2">This is the main phone number that we'll contact.</small>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input @keyup="phoneConfirm" v-model="form.phoneConfirm" @input="acceptNumber2" class="form-control" :class="{'outlineTicketSuccess' : phonec == 1, 'outlineTicketDanger' : phonec == 2}" type="text" placeholder="(777) 245-549">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <div class="form-group">
                                                <label class="form-control-label">Address</label> 
                                                <input v-model="form.address" class="form-control" type="text" placeholder="Enter your home address">
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="form-control-label">Number</label> 
                                                <input v-model="form.address_no" class="form-control" type="tel" placeholder="No.">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">City</label> 
                                                <input v-model="form.city" class="form-control" type="text" placeholder="City">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <select v-model="form.state" class="form-control custom-select">
                                                    <option selected>State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>     
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NJ">New Jersey</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>

                                                </select>
                                               
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">ZIP</label> 
                                                <input v-model="form.zip" class="form-control" type="tel" placeholder="ZIP">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div>
                            <hr>
                            <div v-if="$store.state.shelf.findIndex(item => item.id === 2) != -1" class="">
                                
                                <h5 class="mb-3">Spouse information</h5>
                               
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <input v-model="form.spouse_fn" class="form-control" type="text" placeholder="Enter spouse's first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <input v-model="form.spouse_ln" class="form-control" type="text" placeholder="Also spouse's last name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Date of Birth</label> 
                                                <input v-model="form.spouse_dob" type="text" class="form-control flatpickr-input" data-toggle="date" placeholder="Select your birth date">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Gender</label> 
                                                <select v-model="form.spouse_gender" class="custom-select">
                                                    <option disabled="disabled" selected="selected">Select option</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Rather not say">Rather not say</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                   
                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div>
                            <hr v-if="$store.state.shelf.findIndex(item => item.id === 2) != -1">
                            <div>
                                <h5 class="mb-3">General information</h5>
                                
                                
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Have you used tabacco in the past 12 months?</label> 
                                                <select @change="tState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="$store.state.shelf.findIndex(item => item.id === 2) != -1" class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Has your spouse used tabacco in the past 12 months?</label> 
                                                <select @change="tState2" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Are you working a minimum of 20 hours, and are able to perform the duties of your occupation? ?</label> 
                                                <select @change="hState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Name of Employer</label> 
                                                <input v-model="form.employeer_name" class="form-control" type="text" placeholder="Enter your client's employer">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12 llpshow">
                                            <div class="form-group">
                                                <label class="form-control-label">What's your Occupation</label> 
                                                <input v-model="form.occupation" class="form-control" type="text" placeholder="Enter your client's occupation">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Approx Annual Income </label> 
                                                <input v-model="form.income" class="form-control" type="text" placeholder="Enter your client's approx annual income">
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Has our limited partnership been explained? </label><small @click="showLLP" class="text-small ml-2" style="cursor:pointer;"><i class="uil uil-eye"></i></small> 
                                                <select @change="llpState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Will anyone other than you pay for coverage? (if yes, include their name below)  </label> 
                                                <input v-model="form.paymentalternative" class="form-control" type="text" placeholder="Enter name of anyone other than client who will pay for coverage">
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="mt-3">
                                        
                                    </div>
                                    <h5 class="mt-4 mb-3">Schedule Enrollment</h5>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Select Timezone </label> 
                                                <select @change="selectTimezone" class="form-control custom-select-lg">
                                                    <option selected>Select from menu</option>
                                                    <option value="America/Los_Angeles">Pacific Time - {{$moment(new Date()).tz('America/Los_Angeles').format('h:mm a')}}</option>
                                                    <option value="America/Phoenix">Arizona Time - {{$moment(new Date()).tz('America/Phoenix').format('h:mm a')}}</option>
                                                    <option value="America/Denver">Mountain Time - {{$moment(new Date()).tz('America/Denver').format('h:mm a')}}</option>
                                                    <option value="America/Chicago">Central Time - {{$moment(new Date()).tz('America/Chicago').format('h:mm a')}}</option>
                                                    <option value="America/New_York">Eastern Time - {{$moment(new Date()).tz('America/New_York').format('h:mm a')}}</option>
                                                                                           
                                                </select>
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div v-if="timeZone" class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Schedule Day </label> 
                                                <select @change="selectDateChange" class="form-control custom-select-lg">
                                                    <option selected>Select Date</option>
                                                    <option v-for="(i, index) in sSlots" :key="i.id" :value="index">{{i.day}}</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Schedule Time </label> 
                                                <select @change="selectedHour" class="form-control custom-select-lg">
                                                    <option selected>Select time</option>
                                                    <option v-for="i in sHours" :key="i.id" :disabled="shouldDisableSlot(i)" :value="i.id">{{printDate(i.hour)}}</option>
                                                                                          
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-md-4">
                                        <button v-if="!submitLoading" @click="submitTicket()" type="button" class="btn btn-success">Create Ticket</button>
                                        <div v-else class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade" id="modal_account_deactivate" tabindex="-1" role="dialog" aria-labelledby="modal_account_deactivate" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">
                                            <div class="modal-body">
                                                <div class="pt-5 text-center">
                                                    <div class="icon text-danger">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-x"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="18" y1="8" x2="23" y2="13"></line><line x1="23" y1="8" x2="18" y2="13"></line>
                                                        </svg>
                                                    </div>
                                                    <h4 class="h5 mt-5 mb-3">Extremely important</h4>
                                                    <p>We will immediately delete all of your personal data from our database. This action can not be undone. Are you sure you want to do this?</p>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-sm btn-link text-danger btn-zoom--hover font-weight-600">Delete</button> 
                                                <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <llp 
            ref="llp"
            
        />
</div>  
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import flatpickr from "flatpickr";
import llp from './comps/LLP.vue';
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
export default {
    
    components: {
        flatpickr, llp, VuePhoneNumberInput
    },
    data() {
        return {
            sSlots: '',
            sHours: '',
            emailc: '',
            phonec: '',
            timeZone: '',
            submitLoading: false,
            form: {
                timezone: '',
                policy_coverage: '',
                policy_start: '',
                policy_age: '',
                policy_monthly: '',

                first_name: '',
                last_name: '',
                dob: '',
                gender: '',
                email: '',
                phone: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',

                tobacco: '',
                hours: '',
                employeer_name: '',
                dateofhire: '',
                occupation: '',
                income: '',
                agentexplainedllp: '',
                paymentalternative: '',

                schedule_hourid: '',
            },
            email: "",
            emailValid: '',
            isSS: false,
            isSS2: false,
            
        }
    },
    created() {
        window.scrollTo(0,0);
        this.fetchScheduler();
    },
    mounted() {
        this.form.dob = this.$store.state.policy.dob;
        this.form.gender = this.$store.state.policy.gender;
        this.form.state = this.$store.state.policy.state;

        this.form.policy_age = this.$store.state.policy.age;
        this.form.policy_coverage = this.$store.state.policy.amount;
        this.form.policy_monthly = this.$store.state.policy.quote
        this.form.policy_start = this.$store.state.policy.startdate;

        if(this.$store.state.shelf.findIndex(item => item.id === 2) != -1) {
            this.form.spouse_dob = this.$store.state.policySpouse.dob;
            this.form.spouse_gender = this.$store.state.policySpouse.gender;

            this.form.policyspouse_age = this.$store.state.policySpouse.age;
            this.form.policyspouse_coverage = this.$store.state.policySpouse.amount;
            this.form.policyspouse_monthly = this.$store.state.policySpouse.quote
            this.form.policyspouse_start = this.$store.state.policySpouse.startdate;
        }
        if(this.$store.state.rider1.amount) {
            this.form.policychild_coverage = this.$store.state.rider1.amount;
            this.form.policychild_monthly = this.$store.state.rider1.cost;
        }

        
        

        var dateToday = new Date(); 
       // console.log('01: ' + dateToday);
       // console.log('02: ' + this.$moment(dateToday).utc().format());
       // console.log('1: ' + new Date('7-28-2021 14:00:00'))
       // console.log('2: ' +new Date('7-28-2021 14:00:00').toUTCString());
       // console.log(new Date().toLocaleDateString(undefined, {day:'2-digit',timeZoneName: 'short' }).substring(4));
        
        flatpickr('#datepicker', {
            
            dateFormat: "m-d-Y",
            minDate: dateToday,
            "disable": [
                function(date) {
                return (date.getDay() === 0 || date.getDay() === 6);  // disable weekends
                }
            ],
            "locale": {
                "firstDayOfWeek": 1 // set start day of week to Monday
            }
        });
        flatpickr('#datepicker2', {
            
            dateFormat: "m-d-Y",
           
        }); 
        
    },
    methods: {
        fetchScheduler() {
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get('/api/fetchscheduler').then(resp => {
               console.log(resp.data);
                this.sSlots = resp.data.t;
            });
            
        },
        
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        emailConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.email) {
                    this.emailc = 1;
                }  else {
                    this.emailc = 2;
                }

            } else {
                this.emailc = '';
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        phoneConfirm(event) {
            
            if(event.target.value.length >= 4) {
                console.log(event.target.value);
                if(event.target.value == this.form.phone) {
                    this.phonec = 1;
                } else {
                    this.phonec = 2;
                    console.log('phone not equal ' + this.phonec);
                }

            } else {
                this.phonec = '';
            }
        },
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        acceptNumber2() {
            var x = this.form.phoneConfirm.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phoneConfirm = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        tState(event) {
          if(event.target.value !== '') {
              this.form.tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policy.ss == 1) {
                  this.isSS = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policy.ss == 2) {
                  this.isSS = true;
              }
              
          }
        },
        tState2(event) {
          if(event.target.value !== '') {
              this.form.spouse_tobacco = event.target.value;
              if(event.target.value == 'No' && this.$store.state.policySpouse.ss == 1) {
                  this.isSS2 = true;
              } else if(event.target.value == 'Yes' && this.$store.state.policySpouse.ss == 2) {
                  this.isSS2 = true;
              }
              
          }
        },
        hState(event) {
          if(event.target.value !== '') {
              this.form.hours = event.target.value;
              
          }
        },
        llpState(event) {
          if(event.target.value !== '') {
              this.form.agentexplainedllp = event.target.value;
              
          }
        },
        selectTimezone(event) {
            this.timeZone = event.target.value;
            this.form.timezone = event.target.value;
        },
        selectDateChange(event) {
            console.log(event.target.value);
            this.sHours = this.sSlots[parseInt(event.target.value)].hours;
        },
        shouldDisableSlot(i) {
            if(i.ticket_id) {
                console.log(i);
                return true;
            } 
        },
        selectedHour(event) {
            if(event.target.value != 'Select time' || event.target.value == '') {
                this.form.schedule_hourid = event.target.value;
            }
            
        },
        printDate(i) {
            return moment.utc(i).local().format('hh:mm a');
        },
        printDate2(i) {
            return moment.utc(i).local().format('dddd MMMM DD');
        },
        submitTicket() {
            if(this.form.first_name != '' && this.form.last_name != '' && this.form.dob != '' && this.form.gender != '' && this.form.email != '' && this.emailValid == true && this.emailc == 1 && this.form.phone != '' && this.phonec == 1 && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.isSS == true && this.employeer_name != '' && this.form.occupation != '' && this.form.income != '' && this.form.Hour != '' && this.form.agentexplainedllp != '' && this.form.schedule_hourid != '') {
                this.submitLoading = true;
                let role = JSON.parse(localStorage.getItem('access_token2')).role;
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.post('/api/createticket', this.form).then(resp => {
                    console.log(resp);
                    
                }).then(resp2 => {
                    this.submitLoading = false;
                    this.$toasted.success('Ticket Created');
                    this.$router.push({name: 'admin.tickets'});
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name');
            } else if(this.form.dob == '') {
                this.$toasted.error('Dob');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Invalid Email');
            } else if(this.emailc != 1) {
                this.$toasted.error('Confirm Email');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.phonec != 1) {
                this.$toasted.error('Confirm Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip Code');
            } else if(this.form.gender == '') {
                this.$toasted.error('Gender');
            } else if(this.form.tobacco == '') {
                this.$toasted.error('Tobacco Usage');
            } else if(this.isSS == false) {
                this.$toasted.error('Tobacco Usage Not matching Quoted');
            } else if(this.form.Hour == '') {
                this.$toasted.error('Client Work Hours');
            } else if(this.form.employeer_name == '') {
                this.$toasted.error('Employeer Name');
            } else if(this.form.occupation == '') {
                this.$toasted.error("Client's Occupation");
            } else if(this.form.income == '') {
                this.$toasted.error("Client's Income");
            } else if(this.form.agentexplainedllp == '') {
                this.$toasted.error('Explained LP');
            } else if(this.form.schedule_hourid == '') {
                this.$toasted.error('Schedule Enrollment Hour');
            }
            
        },
        showLLP() {
            $(this.$refs.llp.$el).modal('show');
        },
        closellp() {
            $(this.$refs.llp.$el).modal('hide');
            this.focusForm();
        },
        focusForm:_.debounce(function() {
            const el = this.$el.getElementsByClassName('llpshow')[0];
            console.log(el);
            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
            }
        }, 500),
    }

}
</script>
<style scoped>
#datepicker {
    background-color: white;
    
}
.outlineTicketSuccess {
    border-color: #5cc9a7;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
.form-control option:disabled {
    display: none;
}
</style>