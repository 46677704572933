<template>
<div>
    
    <section class="pt-10 bg-section-secondary">
        <div class=container>
            <div class="row justify-content-center">
                <div class=col-lg-9>
                    <div class="row align-items-center">
                        <div class=col>
                            <span class=surtitle></span>
                            <h1 class="h2 mb-0 text-center">Register</h1>
                            <p class="text-muted mb-0 text-center">Register to become an agent with us</p>
                        </div>
                    </div>
                    <div class="row align-items-center mt-4">
                        <div class=col>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <div class="row">
                        <div class="col-lg-12">
                            
                          
                            <div>
                                <h5 class="mb-3">Agent information</h5>
                                
                                
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <input v-model="form.first_name" class="form-control" type="text" placeholder="Enter your first name">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <input v-model="form.last_name" class="form-control" type="text" placeholder="Also your last name">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <input v-model="form.email" @blur="validateEmail()" class="form-control" type="email" placeholder="name@exmaple.com"> <small class="form-text text-muted mt-2">This is the main email address that we'll send notifications.</small>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <input v-model="form.phone" @input="acceptNumber" class="form-control" type="text" placeholder="(777) 245 549"><small class="form-text text-muted mt-2">This is the main phone number that we'll call.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-sm-9">
                                            <div class="form-group">
                                                <label class="form-control-label">Address</label> 
                                                <input v-model="form.address" class="form-control" type="text" placeholder="Enter your home address">
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="form-control-label">Number</label> 
                                                <input v-model="form.address_no" class="form-control" type="tel" placeholder="No.">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">City</label> 
                                                <input v-model="form.city" class="form-control" type="text" placeholder="City">
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <select @change="stateChange" class="form-control custom-select-lg">
                                                    <option selected>State</option>
                                                    <option value="AL">Alabama</option>
                                                    <option value="AZ">Arizona</option>
                                                    <option value="AR">Arkansas</option>
                                                    <option value="CA">California</option>
                                                    <option value="CO">Colorado</option>
                                                    <option value="CT">Connecticut</option>
                                                    <option value="DE">Delaware</option>
                                                    <option value="FL">Florida</option>
                                                    <option value="GA">Georgia</option>     
                                                    <option value="ID">Idaho</option>
                                                    <option value="IL">Illinois</option>
                                                    <option value="IN">Indiana</option>
                                                    <option value="IA">Iowa</option>
                                                    <option value="KS">Kansas</option>
                                                    <option value="KY">Kentucky</option>
                                                    <option value="LA">Louisiana</option>
                                                    <option value="ME">Maine</option>
                                                    <option value="MD">Maryland</option>
                                                    <option value="MA">Massachusetts</option>
                                                    <option value="MI">Michigan</option>
                                                    <option value="MN">Minnesota</option>
                                                    <option value="MS">Mississippi</option>
                                                    <option value="MO">Missouri</option>
                                                    <option value="MT">Montana</option>
                                                    <option value="NE">Nebraska</option>
                                                    <option value="NV">Nevada</option>
                                                    <option value="NH">New Hampshire</option>
                                                    <option value="NM">New Mexico</option>
                                                    <option value="NY">New York</option>
                                                    <option value="NC">North Carolina</option>
                                                    <option value="ND">North Dakota</option>
                                                    <option value="OH">Ohio</option>
                                                    <option value="OK">Oklahoma</option>
                                                    <option value="OR">Oregon</option>
                                                    <option value="PA">Pennsylvania</option>
                                                    <option value="RI">Rhode Island</option>
                                                    <option value="SC">South Carolina</option>
                                                    <option value="SD">South Dakota</option>
                                                    <option value="TN">Tennessee</option>
                                                    <option value="TX">Texas</option>
                                                    <option value="UT">Utah</option>
                                                    <option value="VT">Vermont</option>
                                                    <option value="VA">Virginia</option>
                                                    <option value="WA">Washington</option>
                                                    <option value="WV">West Virginia</option>
                                                    <option value="WI">Wisconsin</option>
                                                    <option value="WY">Wyoming</option>

                                                </select>
                                               
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">ZIP</label> 
                                                <input v-model="form.zip" class="form-control" type="tel" placeholder="ZIP">
                                            </div>
                                        </div>
                                    </div>
                                    <h5 class="mb-4 mt-3">General information</h5>
                                    <div class="row mt-2">
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Are you full time in the Insurance Business?</label> 
                                                <select @change="fullState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No, I am part time">No, I am part time</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Number of Years selling Life Insurance</label> 
                                                <select @change="sellingState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="1-3">1 - 3</option>
                                                    <option value="4-5">4 - 5</option>
                                                    <option value="6-10">6 - 10</option> 
                                                    <option value="10+">10+</option>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you have any Industry Designations? (If YES please List. If NO please type NO)</label> 
                                                <input v-model="form.industry_designations" class="form-control" type="text" placeholder="">
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Is Life Insurance Your Main Source of Income? *</label> 
                                                <select @change="sourceState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>                                         
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Approximately How Many Life Policies do you sell per YEAR? *</label> 
                                                <select @click="signedState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="1-3">1 - 3</option>
                                                    <option value="4-6">4 - 6</option>
                                                    <option value="12">7 - 12</option> 
                                                    <option value="12+">12+</option>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Could you think of a case that was recently Declined, Rated, or postponed? *</label> 
                                                <select @click="thinkState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    <option value="I have NEVER had a case declined, rated, or postponed">I have NEVER had a case declined, rated, or postponed</option>                                          
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you have Errors and Omissions Insurance? *</label> 
                                                <select @click="errorsState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Is your AML (Anti Money Laundering) Training Up to Date? *</label> 
                                                <select @click="amlState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you Currently "OWE" an Insurance Company and or Agency any money? *</label> 
                                                <select @click="oweState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Do you have a VECTOR record? Please be aware... (Insurance Companies share debt information) Vector is a company where an Insurance company shares with other companies debt owed by an agent or agency. This can delay or decline your Contracting. *</label> 
                                                <select @click="vectorState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Credit Standing, Insurance Companies will Check your Credit History*</label> 
                                                <select @click="creditState" class="form-control custom-select-lg">
                                                    <option selected>Select option</option>
                                                    <option value="I have a good credit score">I have a good credit score</option>
                                                    <option value="I am in bankruptcy">I am in bankruptcy</option>
                                                    <option value="I have been discharged from bankruptcy">I have been discharged from bankruptcy</option>                                         
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">In a few words, tell us how you heard about Visionary</label> 
                                                <textarea v-model="form.ref" class="form-control form-control-lg" data-toggle="autosize" placeholder="A friend, One our recruitors, Social Media ..." rows="3" required="" style="overflow: hidden; overflow-wrap: break-word; resize: none; height: 106px;">
                                                </textarea>
                                            </div>
                                        </div>
                                        
                                      
                                       
                                      
                                        
                                       
                                      
                                    </div>
                                    
                                    <div class="mt-3">
                                        
                                    </div>
                                
                            </div><hr>
                            <div>
                                <div class="page-inner-header mb-4">
                                    <h5 class="mb-1"></h5>
                                    <p class="text-muted mb-0"></p>
                                </div>
                                <div class="row justify-content-end">
                                    <div class="col-md-4">
                                        <button @click="registerAgent()" type="button" class="btn btn-success">
                                            <span v-if="!loading">Register</span>
                                            <div v-else class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  
</template>
<script>
import axios from 'axios';
import flatpickr from "flatpickr";
export default {
    
    components: {
        flatpickr,
    },
    data() {
        return {
            recr: '',
            nope: true,
            form: {
                recruitor_id: '',
               first_name: '',
                last_name: '',

                dob: '',
                gender: '',
                email: '',
                phone: '',
                address: '',
                address_no: '',
                city: '',
                state: '',
                zip: '',
                fulltime: '',
                yearselling: '',
                industry_designations: ' ',
                mainsource: '',
                signedperyear: '',
                thinkcase: '',
                errorsomissions: '',
                amltraining: '',
                oweinsurance: '',
                vectorrecord: '',
                credithistory: '', 
                ref: '',
            },
            loading: false,
            emailValid: '',
        }
    },
    created() {
        window.scrollTo(0,0);
    },
    mounted() {
        flatpickr('#datepicker', {
            dateFormat: "m/d/Y",
            maxDate: "07/28/2003",
        });
    },
    methods: {
        
        formatCurrency(amount) {
            amount = (amount / 100);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
        stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
        },
        fullState(event) {
            if(event.target.value !== '') {
                this.form.fulltime = event.target.value;
                
            }
        },
        sellingState(event) {
            if(event.target.value !== '') {
                this.form.yearselling = event.target.value;
                
            }
        },
        acceptNumber() {
            var x = this.form.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.form.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        sourceState(event) {
            if(event.target.value !== '') {
                this.form.mainsource = event.target.value;
                
            }
        },
        thinkState(event) {
            if(event.target.value !== '') {
                this.form.thinkcase = event.target.value;
                
            }
        },
        errorsState(event) {
            if(event.target.value !== '') {
                this.form.errorsomissions = event.target.value;
                
            }
        },
        amlState(event) {
            if(event.target.value !== '') {
                this.form.amltraining = event.target.value;
                
            }
        },
        oweState(event) {
            if(event.target.value !== '') {
                this.form.oweinsurance = event.target.value;
                
            }
        },
        signedState(event) {
            if(event.target.value !== '') {
                this.form.signedperyear = event.target.value;
                
            }
        },
        vectorState(event) {
            if(event.target.value !== '') {
                this.form.vectorrecord = event.target.value;
                
            }
        },
        creditState(event) {
            if(event.target.value !== '') {
                this.form.credithistory = event.target.value;
                
            }
        },
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        registerAgent() {
            console.log(this.form);
            if(this.form.first_name != '' && this.form_last_name != '' && this.form.email !== '' && this.emailValid == true && this.form.phone != '' && this.form.address != '' && this.form.city != '' && this.form.state != '' && this.form.zip != '' && this.form.fulltime != '' && this.form.yearselling != '' && this.form.mainsource != '' && this.form.signedperyear != '' && this.form.thinkcase != '' && this.form.errorsomissions != '' && this.form.amltraining != '' && this.form.oweinsurance != '' && this.form.vectorrecord != '' && this.form.credithistory != '') {
                this.loading = true;
                
                axios.post('/api/submitinquiry', this.form).then(resp => {
                    console.log(resp.data);
                }).then(resp2 => {
                    this.loading = false;
                    this.$router.push({name: 'Applied'});
                }).catch(error => {
                    this.loading = false;
                });
            } else if(this.form.first_name == '') {
                this.$toasted.error('First Name');
            } else if(this.form.last_name == '') {
                this.$toasted.error('Last Name')
            } else if(this.form.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$toasted.error('Email Invalid');
            } else if(this.form.phone == '') {
                this.$toasted.error('Phone');
            } else if(this.form.address == '') {
                this.$toasted.error('Address');
            } else if(this.form.city == '') {
                this.$toasted.error('City');
            } else if(this.form.state == '') {
                this.$toasted.error('State');
            } else if(this.form.zip == '') {
                this.$toasted.error('Zip');
            } else if(this.form.fulltime == '') {
                this.$toasted.error('Do you work full time?')
            } else if(this.form.yearselling == '') {
                this.$toasted.error('Years Selling');
            } else if(this.form.mainsource == '') {
                this.$toasted.error('Main Source of Income');
            } else if(this.form.signedperyear == '') {
                this.$toasted.error('Signed per Year');
            } else if(this.form.thinkcase == '') {
                this.$toasted.error('Case recently Declined, Rated, or postponed');
            } else if(this.form.errorsomissions == '') {
                this.$toasted.error('Errors and Omissions Insurance')
            } else if(this.form.amltraining == '') {
                this.$toasted.error('AML Training')
            } else if(this.form.oweinsurance == '') {
                this.$toasted.error('Do you Currently "OWE" an Insurance Company?')
            } else if(this.form.vectorrecord == '') {
                this.$toasted.error('Do you have a VECTOR record?');
            } else if(this.form.credithistory == '') {
                this.$toasted.error('Credit History');
            }
            
        }
    }

}
</script>