<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-9">

                    
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards2 align-items-center">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="min-width:240px;border:0px solid white;">Name</th>
                                                <th scope="col" style="border:0px solid white;">Role</th>
                                                
                                                <th scope="col" style="border:0px solid white;"></th>
                                            </tr>
                                        </thead>
                                        <tbody> 
                                            <tr v-for="i in enrList" :key="i.id">
                                                <th scope="row" :style="styleBorder1(i.status)">
                                                    <div class="media align-items-center">
                                                        <div>
                                                            <div class="avatar-parent-child py-2">
                                                                <avatar :username="i.user.name" :size="60" class="rounded-circle"> </avatar> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="media-body ml-4">
                                                            <a href="#" class="name h6 mb-0 text-sm">{{i.user.name}}</a> 
                                                            <small class="d-block font-weight-bold">{{i.user.email}}</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td>
                                                    <div class="media align-items-start">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm">Enroller</span> 
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    
                                                </td>
                                                <td class="text-right">
                                                    <div class="actions ml-6">
                                                        <span class="action-item mr-2" data-toggle="tooltip" title=""><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                            

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                    <!-- <div style="position:absolute;top:0px;margin-top:0px;right:10px;">
                        <div class="col-auto">
                            <button @click="showCreate()" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-plus"></i>
                                </span> 
                                <span class="btn-inner--text">Add Enroller</span>
                            </button>
                        </div>
                    </div> -->
                </div>
                
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
            
        </div>
        </div>
        <enroller-create 
            ref="enrollerCreate"
        />
</div>
</template>
<script>
import axios from 'axios';
import Avatar from 'vue-avatar'
import EnrollerCreate from '../comps/EnrollerCreate.vue';
export default {
    components: {
        Avatar,
        EnrollerCreate,
    },
    data() {
        return {
            loading: false,
            enrList: [],
            page: 1,
            last_page: 1,
            routeInitial: false,
        }
    },
    created() {
        this.fetchEnrollers();
    },
    methods: {
        fetchEnrollers() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`/api/fetchenrollers?page=${this.page}`).then(resp => {
                console.log(resp.data.t);
                this.enrList.push(...resp.data.t.data);
                this.last_page = resp.data.t.last_page;
                
            }).then(resp2 => {
                this.loading = false;
            }).catch(err => { this.loading = false });
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }

            console.log('Bottom');
            this.page++;
            this.fetchEnrollers();
        },
        styleBorder1(i) {
            if(i != 'inactive') {
                return {borderLeft: '4px solid rgba(0,175,0,0.4)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
            }  
            
        },
        showCreate() {
            $(this.$refs.enrollerCreate.$el).modal('show');
        }
    },
    
}
</script>