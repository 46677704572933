<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Agent Contact Information</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-12" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        <div class="col-md-12 mb-1" style="display: flex; justify-content: center;">
                           
                        </div>
                    
                        <div class="col-md-12">
                            <div class="card mt-5">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="">
                                            <div class="list-group list-group-flush">
                                                <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                                    <div class="row w-100">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">First name</label> 
                                                                    <p class="h6">{{$parent.contactInfo.first_name}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Last name</label> 
                                                                    <p class="h6">{{$parent.contactInfo.last_name}}</p>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                                <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                                    <div class="row w-100">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Email</label> 
                                                                    <p class="h6">{{$parent.contactInfo.email}}</p> 
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Phone</label> 
                                                                    <p class="h6">{{$parent.contactInfo.phone}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                                    <div class="row w-100">
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Address</label> 
                                                                    <p class="h6">{{$parent.contactInfo.address}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">Number</label> 
                                                                    <p class="h6">{{$parent.contactInfo.address_no}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="list-group-item d-flex w-100 justify-content-between pb-5" style="border: 0px;">
                                                    <div class="row w-100">
                                                            <div class="col-sm-4">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">City</label> 
                                                                    <p class="h6">{{$parent.contactInfo.city}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">State</label> 
                                                                    <p class="h6">{{$store.state.state[$parent.contactInfo.state]}}</p>
                                                                
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <div class="form-group">
                                                                    <label class="form-control-label">ZIP</label> 
                                                                    <p class="h6">{{$parent.contactInfo.zip}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-auto flex-fill mt-4 mt-sm-0 text-sm-right d-none d-lg-block">
                                            <h4 class="h6"></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                      
                        
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="close()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span v-if="!loading">Close</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "agent-contact",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loading: false,
            form: {
                name: '',
            },
        }
    },
   
    methods: {
        close() {
            $(this.$parent.$refs.agentContact.$el).modal('hide');
        },
        acceptSlot() {
            this.loading = true;
            console.log(this.$parent.sInfo.id);
            this.form.hour_id = this.$parent.sHour.id;
            this.form.user_id = JSON.parse(localStorage.getItem('access_token2')).id;
            this.form.ticket_id = this.$parent.sInfo.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/scheduleslot', this.form).then(resp => {
                console.log(resp.data);
                this.$parent.ticketList[this.$parent.sIndex].enroller_id = resp.data.e;
            }).then(resp2 => {
                this.loading = false;
                
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
                this.$parent.$toasted.success("Scheduled");
            }).catch(err => {
                this.loading = false;
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
            });

        }
    }
    
}
</script>