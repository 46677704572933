<template>
<div>
    
    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                    <h5 class="mb-3 mt-5">Applicant information</h5>
                    <div class="card">
                        <div class="list-group list-group-flush">
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">First name</label> 
                                                <p class="form-control text-muted">{{applicant.first_name}}</p>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Last name</label> 
                                                <p class="form-control text-muted">{{applicant.last_name}}</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Email</label> 
                                                <p class="form-control text-muted">{{applicant.email}}</p> 
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label">Phone</label> 
                                                <p class="form-control text-muted">{{applicant.phone}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-sm-9">
                                            <div class="form-group">
                                                <label class="form-control-label">Address</label> 
                                                <p class="form-control text-muted">{{applicant.address}}</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="form-group">
                                                <label class="form-control-label">Number</label> 
                                                <p class="form-control text-muted">{{applicant.address_no}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div class="list-group-item d-flex w-100 justify-content-between pb-5" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">City</label> 
                                                <p class="form-control text-muted">{{applicant.city}}</p>
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">State</label> 
                                                <p class="form-control text-muted">{{state[applicant.state]}}</p>
                                               
                                            </div>
                                        </div>
                                        <div class="col-sm-4">
                                            <div class="form-group">
                                                <label class="form-control-label">ZIP</label> 
                                                <p class="form-control text-muted">{{applicant.zip}}</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <h5 class="mb-3">General information</h5>
                        <div class="card">
                        <div class="list-group list-group-flush pt-2 pb-3">
                            <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                <div class="row w-100">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label class="form-control-label">Have you completed our marketing agreement?</label> 
                                                <p class="form-control text-muted">{{returnAgreement(applicant.fulltime)}}</p>
                                            </div>
                                        </div>
                                       
                                        
                                        
                                        
                                        
                                       
                                        
                                        
                                        
                                        
                                    </div>
                            </div>
                            
                        </div>
                    </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-md-4">
                            <button @click="showAppAccept()" type="button" class="btn btn-primary">Accept Agent</button>
                        </div>
                    </div>
                    <div style="position:absolute;top:0px;margin-top:-14px;left:10px;">
                        <div class="col-auto">
                            <button @click="$router.go(-1)" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill">
                                <span class="btn-inner--icon">
                                    <i class="uil uil-arrow-left"></i>
                                </span> 
                                <span class="btn-inner--text">Back</span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <applicant-accept 
            ref="appAccept"
            
        />
</div>
</template>
<script>
import applicantAccept from '../comps/ApplicantAccept.vue';
export default {
    components: {
        applicantAccept,
    },
    data() {
        return {
            applicant: '',
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NY: "New York",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
        }
    },
    created() {
        window.scrollTo(0,0);
        this.applicant = this.$route.query.applicant;
        console.log(this.applicant.first_name);
    },
    methods: {
        showAppAccept() {          
                $(this.$refs.appAccept.$el).modal('show');
        },
        returnAgreement(i) {
            if(i == true) {
                return 'Yes';
            } else if(i == false) {
                return 'No';
            }
        }
    }
}
</script>