<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-12">

                    
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                <div class="col-10 form-group mb-5" style="margin: 0 auto;">
                                    
                                    
                                    <div class="btn-group-toggle d-flex w-100 mt-2" data-toggle="buttons">
                                        <label class="btn btn-lg btn-neutral btn-icon flex-fill mr-sm-4 active">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user mx-3 mr-sm-3"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle>
                                            </svg> 
                                        <input @click="agentInquiry()" type="radio" name="billing_entity" id="person" checked="checked">Agent Inquiry</label> 
                                        <label class="btn btn-lg btn-neutral flex-fill ml-sm-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users mx-3 mr-sm-3"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            </svg> 
                                        <input @click="allMessages()" type="radio" name="billing_entity" id="company">Messages</label>
                                    </div>
                                </div>
                                <div v-if="showContent" class="col-lg-10 table-responsive" style="margin: 0 auto">
                                    <table class="table table-cards2 align-items-center">
                                        <thead>
                                            <tr style="">
                                                <th scope="col" style="max-width:200px;;border:0px solid white;">Name</th>
                                                <th scope="col" style="border:0px solid white;">City / State</th>
                                                <th scope="col" style="border: 0px solid white">Applied</th>
                                                <th scope="col" style="border: 0px solid white"></th>
                                            </tr>
                                        </thead>
                                        
                                        <tbody class="">
                                            <tr v-for="(i, index) in ticketList" :key="i.id">
                                                <th scope="row" :style="styleBorder1(i.viewed)">
                                                    <div class="media align-items-center">
                                                        <div>
                                                            <div class="avatar-parent-child">
                                                                <avatar :username="returnName(i.first_name, i.last_name)" :size="60" class="rounded-circle"> </avatar> 
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="media-body ml-4">
                                                            <a href="#" class="name h6 mb-0 text-sm">{{i.first_name + ' ' + i.last_name}}</a> 
                                                            <small class="d-block font-weight-bold">{{i.email}}</small>
                                                        </div>
                                                    </div>
                                                </th>
                                                <td :style="">
                                                    <div class="media align-items-start">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{i.city}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{$store.state.state[i.state]}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td :style="">
                                                    <div class="media align-items-between">
                                                        
                                                        <div class="media-body">
                                                            <span class="name h5 mb-0 text-sm">{{$moment(i.created_at).local().format('ll')}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-right" :style="">
                                                    <div class="actions ml-3">
                                                        <button @click="showIView(i, index)" class="action-item mr-2" data-toggle="tooltip" title="">
                                                            <i class="uil uil-user-square " style="font-size:1.18em;"></i>
                                                        </button>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else class="row mt-6">
                                        <div v-if="loadingS" style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                                            <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="col-6" v-for="(i, index) in messagesList" :key="i.id">
                                            <div class="card card-fluid" :style="styleBorder(i.viewed)">
                                                
                                            <div class="card-body">
                                                <div class="row pt-3" style="">
                                                    <div class="col-8 text-start pl-4">
                                                        <span class="h6"><i class="uil uil-user"></i> {{i.name}}</span><br>
                                                        <span class="h6 mb-0"><i class="uil uil-envelope"></i> {{i.email}}</span>
                                                    </div>
                                                    
                                                    <div class="col-4 text-center">
                                                        <span class="h6 mb-0"><i class="uil uil-phone"></i> {{i.phone}}</span>
                                                    </div>
                                                </div>
                                                <div class="row pt-4 justify-content-center">
                                                    <div class="col-10">
                                                        <p class="text mb-0">{{i.message}}</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="card-footer py-2">
                                                <div class="row align-items-center">
                                                    <div class="col-6">
                                                        <button type="button" class="btn btn-sm px-0 font-weight-bold btn-link text-primary btn-icon">Message</button>
                                                    </div>
                                                    <div class="col-6 text-right">
                                                        <button @click="showMViewed(i.id, index)" type="button" class="btn btn-xs btn-outline-primary btn-icon rounded-pill">
                                                            <span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
                                                            </span> 
                                                            <span class="btn-inner--text">Viewed</span>
                                                        </button>
                                                        <button @click="showMRemove(i, index)" type="button" class="btn btn-xs btn-outline-danger rounded-pill">Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div v-observe-visibility="scrollBottom2"></div>
                                </div>
                                
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </div>
        <inquiry-view
            ref="iView"
        />
        <message-viewed
            ref="mViewed"
        />
        <message-remove
            ref="mRemove"
        />
</div>
</template>
<script>
import axios from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import Avatar from 'vue-avatar'

import enrollerAccept from '../comps/EnrollerAccept.vue';
import inquiryView from '../comps/InquiryView.vue';
import messageViewed from '../comps/MessageViewed.vue';
import messageRemove from '../comps/MessageRemove.vue';
export default {
    components: {
        InfiniteLoading,
        enrollerAccept,
        Avatar,
        inquiryView,
        messageViewed,
        messageRemove,
    },
    data() {
        return {
            role : JSON.parse(localStorage.getItem('access_token2')).role,
            loading: false,
            loadingS: false,
            showContent: true,
            messagesLoaded: false,
            ticketList: [],
            messagesList: [],
            sInfo: {},
            sHour: '',
            sIndex: '',
            page: 0,
            pagem: 1,
            last_page: 1,
            last_pagem: 1,
            routeInitial: false,
            contactInfo: '',
            mViewed: '',
            mIndex: '',
            iviewConfirmPre: false,
            iviewConfirmPreDel: false,
        }
    },
    created() {
        //this.fetchTickets();
        window.scrollTo(0,0);
    },
    computed: {
        
    },
    methods: {
        fetchApplicants() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                    this.loadingS = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`api/fetchinquiry?page=${this.page}`).then(resp => {
                console.log(resp);
                this.last_page = resp.data.t.last_page;
                this.ticketList.push(...resp.data.t.data);
            }).then(resp2 => {
                this.loading = false;
            }).catch(error => {
                this.loading= false;
            });
        },
        fetchTickets() {
            let role = JSON.parse(localStorage.getItem('access_token2')).role;
            console.log(role);
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            if (role.toLowerCase().includes('enroller') || role.toLowerCase().includes('recruitor') || role.toLowerCase().includes('admin')) {
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
                axios.get(`/api/fetchticketsall?page=${this.page}`).then(resp => {
                    console.log(resp);
                    this.last_page = resp.data.t.last_page;
                    this.ticketList.push(...resp.data.t.data);
                    
                }).then(resp2 => {
                    this.loading = false;
                
                }).catch(err => { this.loading = false });

            } else if(role.toLowerCase().includes('agent')) {
                console.log('fetching from agent');
                if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
                axios.get(`/api/fetchticketsagent?page=${this.page}`).then(resp => {
                    console.log(resp.data);
                    this.last_page = resp.data.t.last_page;
                    this.ticketList.push(...resp.data.t.data);
                    this.loading = false;
                }).catch(err => { this.loading = false });
            }
            
            
        },
        fetchMessages() {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
                axios.get(`/api/fetchmessages?page=${this.page}`).then(resp => {
                    console.log(resp);
                    this.last_pagem = resp.data.t.last_page;
                    this.messagesList.push(...resp.data.t.data)
                    this.loadingS = false;
                });
        
            
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }
            console.log('Bottom');
            this.page++;
            this.fetchApplicants();
        },
        scrollBottom2(isVisible) {
            if(!isVisible) { return }
            if(this.pagem >= this.last_pagem) { return }
            console.log('Bottom');
            this.pagem++;
            this.fetchMessages();
        },
        returnName(a, b) {
            return a + ' ' + b;
        },
        agentInquiry() {
            this.showContent = true;
            console.log('agent selected');
        },
        allMessages() {
            this.showContent = false;
            if(this.messagesLoaded == false) {
                this.fetchMessages();
                this.messagesLoaded = true;
            }
            console.log('reading messages');
        },
        styleBorder(i) {
            if(i == false) {
                return {borderLeft: '3px solid #5cc9a7'}
            } else {
                return {}
            } 
            
        },
        styleBorder1(i) {
            if(i == 0) {
                return {borderLeft: '4px solid rgba(0,100,0,0.4)', borderTop: '0px solid #5cc9a7', borderBottom: '0px solid #5cc9a7'}
            }  
            
        },
        styleBorder2(i) {
            if(i == 0) {
                return { borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
         },
        styleBorder3(i) {
            if(i == 0) {
                return {borderRight: '2px solid #5cc9a7', borderTop: '1px solid #5cc9a7', borderBottom: '1px solid #5cc9a7'}
            } 
        },
        styleSuccessRight(date) {
            return date == null ? {borderRight: '1px solid #5cc9a7;'} : {};
        },
        styleSuccessBottom(date) {
            return date == null ? {borderBottom: '1px solid #5cc9a7;'} : {};
        },
        showEnrAccept(i, index) {
            this.sInfo = i;
            this.sHour = i.hour;
            this.sIndex = index;
            if(i.hour) {
                $(this.$refs.enrAccept.$el).modal('show');
            } else {
                this.sInfo = '';
                this.sHour = '';
            }
            
        },
        showIView(i, index) {
            this.contactInfo = i;
            this.iIndex = index;
            this.iviewConfirmPre = false;
            this.iviewConfirmPreDel = false;
            $(this.$refs.iView.$el).modal('show');
        },
        showMViewed(i, index) {
            this.mViewed = i;
            this.mIndex = index,
            $(this.$refs.mViewed.$el).modal('show');
        },
        showMRemove(i, index) {
            this.mViewed = i;
            this.mIndex = index;
            $(this.$refs.mRemove.$el).modal('show');
        }
    }
}
</script>
<style>
.tc2 tbody tr th {
    border-radius: .75rem 0 0 .75rem;
}

.tc2 tbody th {
    padding: 0 1rem;
}
.tc2 tbody th {
    position: relative;
}
.tc2 {
    border-spacing: 0 1rem;
}
.outlineTicketSuccess {
    border-color: #5cc9a7;
    border-left: 10px solid;
}
.outlineTicketDanger {
    border-color: #ee283c;;
}
</style>