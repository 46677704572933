<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div v-if="!loading" class="row justify-content-center">
                <div class="col-lg-10">

                    
                    <div class="row mt-2">
                        <div class="col-lg-12">
                            <div class="mb-10">
                                
                                <div class="table-responsive">
                                    <table class="table table-cards2 align-items-center">
                                        <thead>
                                            <tr style="">
                                                <th scope="col" style="max-width:200px;;border:0px solid white;">Name</th>
                                                <th scope="col" style="border:0px solid white">Agent</th>
                                                <th scope="col" style="border:0px solid white;">City / State</th>
                                                <th scope="col" style="border: 0px solid white">Schedule Date</th>
                                                <th scope="col" style="border: 0px solid white"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(i, index) in ticketList" :key="i.id" >
                                                    <th  scope="row" style="border-left:2px solid #ffbe3d;border-top:1px solid #ffbe3d;border-bottom:1px solid #ffbe3d">
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body ml-4">
                                                                <a href="#" class="name h6 mb-0 text-sm">{{i.ticket.first_name}} {{i.ticket.last_name}}</a> 
                                                                <small class="d-block font-weight-bold">{{i.ticket.email}}</small>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <td style="border-top: 1px solid #ffbe3d;border-bottom:1px solid #ffbe3d">
                                                        <div class="media align-items-center">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h6 mb-0 text-sm">{{i.ticket.agent.user.name}}</span> 
                                                            
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style="border-top: 1px solid #ffbe3d;border-bottom:1px solid #ffbe3d">
                                                        <div class="media align-items-start">
                                                            
                                                            <div class="media-body">
                                                                <span class="name h5 mb-0 text-sm">{{i.ticket.city}}</span> 
                                                                <span class="d-block font-weight-light text-muted">{{state[i.ticket.state]}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style="border-top: 1px solid #ffbe3d;border-bottom:1px solid #ffbe3d">
                                                        <div class="media align-items-start">
                                                            
                                                            <div  v-if="i.hour" class="media-body text-center">
                                                            <span class="name h5 mb-0 text-sm">{{$moment(i.ticket.hour.hour + 'z').local().format('h:mm a')}}</span> 
                                                            <span class="d-block font-weight-light text-muted">{{$moment(i.ticket.hour.hour + 'z').local().format('dddd MMM, D')}}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-right" style="border-right:2px solid #ffbe3d;border-top: 1px solid #ffbe3d;border-bottom:1px solid #ffbe3d">
                                                        <div class="actions ml-3" style="display:flex;align-items:center;justify-content:end;">
                                                            
                                                            <router-link :to="{name: 'admin.ticket.view', params: {id: i.id}, query: {ticket: i.ticket}}" class="action-item mr-2" data-toggle="tooltip" title="" >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-link"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg> 
                                                            </router-link>
                                                        </div>
                                                    </td>
                                            </tr>
                                            <tr v-observe-visibility="scrollBottom"></tr>
                                        </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div v-else style="height:145px;width:100%;display:flex;align-items:center;justify-content:center;">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        </div>
</div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading: false,
            ticketList: [],
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NY: "New York",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
            page: 1,
            last_page: 1,
            routeInitial: false,
        }
    },
    created() {
        this.fetchTickets();
    },
    methods: {
        fetchTickets() {
            if(this.routeInitial == false) {
                    this.loading = true;
                    this.routeInitial = true;
                }
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.get(`/api/fetchenrollerslots?page=${this.page}`).then(resp => {
                console.log(resp.data);
                this.last_page = resp.data.s.last_page;
                this.ticketList.push(...resp.data.s.data);
                this.loading = false;
            }).catch(err => { this.loading = false });
        },
        scrollBottom(isVisible) {
            if(!isVisible) { return }
            if(this.page >= this.last_page) { return }

            console.log('Bottom');
            this.page++;
            this.fetchTickets();
        },
    }
}
</script>