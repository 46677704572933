<template>
    <div class="modal fade docs-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-12" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        <div class="col-md-12 mb-1" style="display: flex; justify-content: center;">
                           
                        </div>
                    
                        <div class="col-lg-9">
                            <h5 class="mb-3 mt-5">Applicant information</h5>
                            <div class="card">
                                <div class="list-group list-group-flush">
                                    <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                        <div class="row w-100">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-control-label">First name</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.first_name}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Last name</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.last_name}}</p>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    
                                    <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                        <div class="row w-100">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Email</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.email}}</p> 
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Phone</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.phone}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                        <div class="row w-100">
                                                <div class="col-sm-9">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Address</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.address}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Number</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.address_no}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div class="list-group-item d-flex w-100 justify-content-between pb-5" style="border: 0px;">
                                        <div class="row w-100">
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label class="form-control-label">City</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.city}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label class="form-control-label">State</label> 
                                                        <p class="form-control text-muted">{{$store.state.state[$parent.contactInfo.state]}}</p>
                                                    
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group">
                                                        <label class="form-control-label">ZIP</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.zip}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5">
                                <h5 class="mb-3">General information</h5>
                                <div class="card">
                                <div class="list-group list-group-flush pt-2 pb-3">
                                    <div class="list-group-item d-flex w-100 justify-content-between" style="border: 0px;">
                                        <div class="row w-100">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Are you full time in the Insurance Business?</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.fulltime}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Number of Years selling Life Insurance</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.yearselling}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Do you have any Industry Designations? (If YES please List. If NO please type NO)</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.industry_designations}}</p>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Is Life Insurance Your Main Source of Income? *</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.mainsource}}</p>

                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Approximately How Many Life Policies do you sell per YEAR? *</label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.signedperyear}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Could you think of a case that was recently Declined, Rated, or postponed? * </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.thinkcase}}</p>
                                                    </div>
                                                </div>
                                            
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Do you have Errors and Omissions Insurance? * </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.errorsomissions}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Is your AML (Anti Money Laundering) Training Up to Date? * </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.amltraining}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Do you Currently "OWE" an Insurance Company and or Agency any money? * </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.oweinsurance}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Do you have a VECTOR record? Please be aware... (Insurance Companies share debt information) Vector is a company where an Insurance company shares with other companies debt owed by an agent or agency. This can delay or decline your Contracting. * </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.vectorrecord}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">Credit Standing, Insurance Companies will Check your Credit History: * </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.credithistory}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="form-control-label">In a few words, tell us how you heard about Visionary </label> 
                                                        <p class="form-control text-muted">{{$parent.contactInfo.ref}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    
                                </div>
                            </div>
                            </div>
                           
                        </div>
                     
                      
                        
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button v-if="!$parent.iviewConfirmPre" @click="acceptViewed()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span>Viewed</span>
                                
                            </button>
                            <button v-else @click="acceptViewedConfirmed()" type="submit" class="btn btn-success btn-loading">
                                
                                <span v-if="!loadingV">Confirm</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>

                            <button v-if="!$parent.iviewConfirmPreDel" @click="del()" type="submit" class="btn btn-danger btn-loading">
                                
                                <span>Delete</span>
                                
                            </button>
                            <button v-else @click="delConfirm()" type="submit" class="btn btn-danger btn-loading">
                                
                                <span v-if="!loadingD">Confirm</span>
                                <div v-else class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>
import Avatar from 'vue-avatar'
import axios from 'axios';
export default {
    name: "inquiry-view",
   
    components: {
        Avatar,
    },
    
    data() {
        return {
            slot: '',
            loadingV: false,
            loadingD: false,
            viewConfirm: false,
            form: {
                inquiry_id: '',
            },
        }
    },
   created() {
       this.viewConfirm = false;
       console.log('something here');
   },
   mounted() {
       console.log('mounted');
   },
    methods: {
        acceptViewed() {
           this.$parent.iviewConfirmPre = true;
           console.log('this');
            
        },
        acceptViewedConfirmed() {
            this.loadingV = true;
            this.form.inquiry_id = this.$parent.contactInfo.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/inquiryviewed', this.form).then(resp => {
                console.log(resp.data);
                this.$parent.ticketList[this.$parent.iIndex].viewed = true;
            }).then(resp2 => {
                this.loadingV = false;
                
                $(this.$parent.$refs.iView.$el).modal('hide');
                this.$parent.$toasted.success("Viewed");
            }).catch(err => {
                this.loadingV = false;
                $(this.$parent.$refs.iView.$el).modal('Viewed');
            });

        },
        del() {
            this.$parent.iviewConfirmPreDel = true;
        },
        delConfirm() {
            this.loadingD = true;
            this.form.inquiry_id = this.$parent.contactInfo.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/removeinquiry', this.form).then(resp => {
                console.log(resp.data);
                 this.$parent.ticketList.splice(this.$parent.iIndex, 1);
            }).then(resp2 => {
                this.loadingD = false;
                
                $(this.$parent.$refs.iView.$el).modal('hide');
                this.$parent.$toasted.success("Deleted");
            }).catch(err => {
                this.loadingD = false;
                $(this.$parent.$refs.iView.$el).modal('hide');
            });

        },
        acceptSlot() {
            this.loading = true;
            console.log(this.$parent.sInfo.id);
            this.form.hour_id = this.$parent.sHour.id;
            this.form.user_id = JSON.parse(localStorage.getItem('access_token2')).id;
            this.form.ticket_id = this.$parent.sInfo.id;
            axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
            axios.post('/api/scheduleslot', this.form).then(resp => {
                console.log(resp.data);
                this.$parent.ticketList[this.$parent.sIndex].enroller_id = resp.data.e;
            }).then(resp2 => {
                this.loading = false;
                
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
                this.$parent.$toasted.success("Scheduled");
            }).catch(err => {
                this.loading = false;
                $(this.$parent.$refs.enrAccept.$el).modal('hide');
            });

        }
    }
    
}
</script>