<template>
<div>

    <div class="slice slice-sm bg-section-secondary">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">

                    
                    <div class="row mt-2 mt-4 pb-5">
                        <div class="col-10" style="margin: 0 auto;">
                            <div class="card rounded-bottom-right">
                                <div class="card-body">
                                    <div class="d-flex align-items-center mb-4">
                                        <h3 class="display-5 text-dark my-2 " style="margin: 0 auto;">Life Quoter</h3>
                                    </div>
                                    <div class="row justify-content-center list-group-flush mb-4">
                                        <div class="col-12">
                                            <div id="form-contact">
                                                
                                                <div class="form-group">
                                                    
                                                    <select @change="amountChange" class="form-control custom-select-lg">
                                                        <option selected>Amount</option>
                                                        <option value="100">$100,000</option>
                                                        <option value="95">$95,000</option>
                                                        <option value="90">$90,000</option>
                                                        <option value="85">$85,000</option>
                                                        <option value="80">$80,000</option>
                                                        <option value="75">$75,000</option>
                                                        <option value="70">$70,000</option>
                                                        <option value="65">$65,000</option>
                                                        <option value="60">$60,000</option>
                                                        <option value="55">$55,000</option>
                                                        <option value="50">$50,000</option>
                                                        <option value="45">$45,000</option>
                                                        <option value="40">$40,000</option>
                                                        <option value="35">$35,000</option>
                                                        <option value="30">$30,000</option>
                                                        <option value="25">$25,000</option>
                                                        <option value="20">$20,000</option>
                                                        <option value="15">$15,000</option>
                                                        <option value="10">$10,000</option>
                                                        <option value="5">$5,000</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    
                                                    <select @change="stateChange" class="form-control custom-select-lg">
                                                        <option selected>State</option>
                                                        <option value="AL">Alabama</option>
                                                        <option value="AZ">Arizona</option>
                                                        <option value="AR">Arkansas</option>
                                                        <option value="CA">California</option>
                                                        <option value="CO">Colorado</option>
                                                        <option value="CT">Connecticut</option>
                                                        <option value="DE">Delaware</option>
                                                        <option value="FL">Florida</option>
                                                        <option value="GA">Georgia</option>     
                                                        <option value="ID">Idaho</option>
                                                        <option value="IL">Illinois</option>
                                                        <option value="IN">Indiana</option>
                                                        <option value="IA">Iowa</option>
                                                        <option value="KS">Kansas</option>
                                                        <option value="KY">Kentucky</option>
                                                        <option value="LA">Louisiana</option>
                                                        <option value="ME">Maine</option>
                                                        <option value="MD">Maryland</option>
                                                        <option value="MA">Massachusetts</option>
                                                        <option value="MI">Michigan</option>
                                                        <option value="MN">Minnesota</option>
                                                        <option value="MS">Mississippi</option>
                                                        <option value="MO">Missouri</option>
                                                        <option value="MT">Montana</option>
                                                        <option value="NE">Nebraska</option>
                                                        <option value="NV">Nevada</option>
                                                        <option value="NH">New Hampshire</option>
                                                        <option value="NM">New Mexico</option>
                                                        <option value="NY">New York</option>
                                                        <option value="NC">North Carolina</option>
                                                        <option value="ND">North Dakota</option>
                                                        <option value="OH">Ohio</option>
                                                        <option value="OK">Oklahoma</option>
                                                        <option value="OR">Oregon</option>
                                                        <option value="PA">Pennsylvania</option>
                                                        <option value="RI">Rhode Island</option>
                                                        <option value="SC">South Carolina</option>
                                                        <option value="SD">South Dakota</option>
                                                        <option value="TN">Tennessee</option>
                                                        <option value="TX">Texas</option>
                                                        <option value="UT">Utah</option>
                                                        <option value="VT">Vermont</option>
                                                        <option value="VA">Virginia</option>
                                                        <option value="WA">Washington</option>
                                                        <option value="WV">West Virginia</option>
                                                        <option value="WI">Wisconsin</option>
                                                        <option value="WY">Wyoming</option>

                                                    </select>
                                                </div>
                                                <div class="form-group">
                                            
                                                    <select @change="ssChange" class="form-control custom-select-lg">
                                                        <option selected>Smoking Status</option>
                                                        <option value="1">No</option>
                                                        <option value="2">Yes</option>                                          
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <select @change="genderChange" class="form-control custom-select-lg">
                                                        <option selected>Gender</option>
                                                        <option value="Male">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                                
                                                <div class="form-group">
                                                    
                                                    <div class="row">
                                                        <div class="col-4" style="padding-right:2px;">
                                                            <select @change="changeMonth" class="form-control custom-select-lg">
                                                                <option selected>Month</option>
                                                                <option value="01">01 - January</option>
                                                                <option value="02">02 - February</option>
                                                                <option value="03">03 - March</option>
                                                                <option value="04">04 - April</option>
                                                                <option value="05">05 - May</option>
                                                                <option value="06">06 - June</option>
                                                                <option value="07">07 - July</option>
                                                                <option value="08">08 - August</option>
                                                                <option value="09">09 - September</option>
                                                                <option value="10">10 - October</option>
                                                                <option value="11">11 - November</option>
                                                                <option value="12">12 - December</option>
                                                            </select> 
                                                        </div>
                                                        <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                                        
                                                            <select @change="changeDay" class="form-control custom-select-lg">
                                                                <option selected>Day</option>
                                                                <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                            
                                                            </select> 
                                                        </div>
                                                        <div class="col-4" style="padding-left:2px;">
                                                        <select @change="changeYear" class="form-control custom-select-lg" >
                                                                <option selected>Year</option>
                                                                <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                                            </select> 
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="text-center">
                                                    <button type="reset" class="btn-reset d-none">

                                                    </button> 
                                                    <button type="submit" @click="submitQuote" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                                        <span v-if="loading == false">Get your Estimate</span>
                                                        <span v-else>
                                                            <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
</div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            loading: false,
            todayYear: new Date().getFullYear() - 18,
            form: {
                amount: '',
                state: '',
                ss: '',
                gender: '',
                dob: '',
            },
            selectedDay: '',
            selectedMonth: '',
            selectedYear: '',
            state: {
                AL: "Alabama",
                AZ: "Arizona",
                AR: "Arkansas",
                CA: "California",
                CO: "Colorado",
                CT: "Connecticut",
                DE: "Delaware",
                FL: "Florida",
                GA: "Georgia" ,    
                ID: "Idaho",
                IL: "Illinois",
                IN: "Indiana",
                IA: "Iowa",
                KS: "Kansas",
                KY: "Kentucky",
                LA: "Louisiana",
                ME: "Maine",
                MD: "Maryland",
                MA: "Massachusetts",
                MI: "Michigan",
                MN: "Minnesota",
                MS: "Mississippi",
                MO: "Missouri",
                MT: "Montana",
                NE: "Nebraska",
                NV: "Nevada",
                NH: "New Hampshire",
                NM: "New Mexico",
                NY: "New York",
                NC: "North Carolina",
                ND: "North Dakota",
                OH: "Ohio",
                OK: "Oklahoma",
                OR: "Oregon",
                PA: "Pennsylvania",
                RI: "Rhode Island",
                SC: "South Carolina",
                SD: "South Dakota",
                TN: "Tennessee",
                TX: "Texas",
                UT: "Utah",
                VT: "Vermont",
                VA: "Virginia",
                WA: "Washington",
                WV: "West Virginia",
                WI: "Wisconsin",
                WY: "Wyoming",
            },
        }
    },
    created() {
      
    },
    methods: {
        
        submitQuote() {
            console.log('submitting quote');
            console.log(this.form);
            
            let policy = {};
            if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
                this.loading = true;
                this.$store.dispatch('clearShelf');
                this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
                axios.post("/api/fetchquote", this.form).then(resp => {
                    console.log(resp.data.quote);
                    console.log(resp);
                    policy.quote = parseInt(resp.data.quote) + 400;
                    policy.ss = this.form.ss;
                    policy.amount = this.form.amount;
                    policy.startdate = resp.data.policystart;
                    policy.age = resp.data.diff;
                    policy.dob = this.form.dob;
                    policy.gender = this.form.gender;
                    policy.state = this.form.state;
                    this.$store.commit('addPolicy', policy);
                }). then(resp2 => {
                    this.loading = false;
                    this.$router.push({name: 'Quote'})
                });
            } else if(this.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.form.ss == '') {
                this.$toasted.error('Select Smoking Status');
            } else if(this.form.gender == '') {
                this.$toasted.error('Select Gender');
            } else if(this.selectedDay == '') {
                this.$toasted.error('Select Day');
            } else if(this.selectedMonth == '') {
                this.$toasted.error('Select Month');
            } else if(this.selectedYear == '') {
                this.$toasted.error('Select Year');
            }
          
        },
        amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
        },
        stateChange(event) {
            if(event.target.value !== "State") {
                this.form.state = event.target.value;
                console.log('succesfully changed states');
            } else {
                console.log("please select from the dropdown");
            }
        },
        ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
        },
        genderChange(event) {
            if(event.target.value !== "Gender") {
                this.form.gender = event.target.value;
                console.log('succesfully selected gender');
            } else {
                console.log("please select from the dropdown");
            }
        },
        changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
        },
        changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
        },
        changeYear(event) {
            if(event.target.value !== 'Year') {
                this.selectedYear = event.target.value;
                console.log(event.target.value);
            }
        },
    },
}
</script>